import 'babel-polyfill';
// import svg4everybody from 'svg4everybody';
import $ from 'jquery';
import validate from "jquery-validation";
import select2 from '../../node_modules/select2/dist/js/select2.full';
import Inputmask from "inputmask";
import Swiper from 'swiper';
import ionRangeSlider from 'ion-rangeslider';
import 'tooltipster/dist/js/tooltipster.main.min';
import 'tooltipster/dist/js/tooltipster.bundle.min';
import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import gsap from "gsap";

import mCustomScrollbar from '../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar'

select2($);
mCustomScrollbar($)
window.$ = $;
window.jQuery = $;
window.validate = validate;
window.select2 = select2;
window.Inputmask = Inputmask;
window.mCustomScrollbar = mCustomScrollbar;
window.Swiper = Swiper;
window.$W = $(window);
window.$D = $(document);
window.$H = $('html');
window.$B = $('body');
window.Fancybox= Fancybox; 
window.gsap= gsap;

// svg4everybody();
// import 'ninelines-ua-parser';
